export default [
  {
    header: 'Tài chính',
    resource: 'finance',
    action: 'read',
  },

  {
    title: 'Hoá đơn',
    route: 'invoice-list',
    icon: 'FileTextIcon',
    resource: 'invoice',
    action: 'read',
  },
  {
    title: 'Lịch sử thanh toán',
    route: 'income-expense-list',
    icon: 'FolderPlusIcon',
    resource: 'income-expense',
    action: 'read',
  },

];
