export default [
  {
    header: 'Cài đặt',
    resource: 'setting',
    action: 'read',
  },
  {
    title: 'Tài khoản Tingee',
    icon: 'SettingsIcon',
    route: 'tingees',
    resource: 'all',
    action: 'read',
  },
  // {
  //   title: 'Mẫu hóa đơn',
  //   icon: 'SettingsIcon',
  //   route: 'settings',
  //   resource: 'setting',
  //   action: 'read',
  // },
  // {
  //   title: 'Mẫu thông báo Zalo',
  //   icon: 'SettingsIcon',
  //   route: 'settings',
  //   resource: 'setting',
  //   action: 'read',
  // },

];
